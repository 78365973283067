import React, { useEffect, useState } from 'react';
import { KJUR } from 'jsrsasign';
import './App.css';
import { ZoomMtg } from '@zoomus/websdk';
import socket from './socket.io';
import siteSetting from './config/env';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.14.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

let socketInfo;

function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const channelId = urlParams.get('channelId');
  const meetingId = urlParams.get('meetingId');
  const zak = urlParams.get('zak');
  const token = urlParams.get('token');
  const passWord = urlParams.get('pwd');

  const _config = {
    authEndpoint: siteSetting.webConfig.BASE_URL,
    sdkKey: '',
    sdkSecret: '',
    meetingNumber: '',
    passWord: '',
    role: !zak ? 0 : 1,
    zakToken: '',  // optional
    userName: '',      //optional
    userEmail: '',    //optional
    leaveUrl: siteSetting.webConfig.BASE_URL + '?leave=true' // optional
    // registrantToken:'', //optional
  };
  const [config, setConfig] = useState(_config);


  function getSignature() {

    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2
    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
      sdkKey: config.sdkKey,
      mn: config.meetingNumber,
      role: config.role, //ned to integration 
      iat: iat,
      exp: exp,
      appKey: config.sdkKey,
      tokenExp: iat + 60 * 60 * 2
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, config.sdkSecret);

    startMeeting(signature)
  }

  function startMeeting(signature) {

    let zoomConfig = {
      signature: signature,
      sdkKey: config.sdkKey,
      meetingNumber: config.meetingNumber,
      passWord: config.passWord,
      userName: config.userName,
      userEmail: config.userEmail,
    }
    if(zak){
      zoomConfig.zak = config.zakToken
    }
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: config.leaveUrl,
      disableCORP: !window.crossOriginIsolated, // default true,
      success: (success) => {
        ZoomMtg.join({
          ...zoomConfig,
          success: (success) => {
            console.log('success zoom')
          },
          error: (error) => {
            console.log('error', error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    });



    ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
      if (data && data.meetingStatus && data.meetingStatus == 3) {
        socketInfo.emit(
          "zoomMeetingReq",
          JSON.stringify({ channelId: channelId, status: 'remove' })
        );
      }
    });

  }

  useEffect(() => {

    if (Object.keys(config).length && config.sdkKey != '') {
      getSignature();
    }

  }, [config]);


  //close window after click leave meeting
  useEffect(() => {
    //after clicking leave meeting, and close leave
    const leaveUser = urlParams.get('leave');
    if (leaveUser)
      window.close();
  }, [urlParams]);




  //=============== set config by api 
  const callApi = () => {

    fetch(siteSetting.webConfig.API_URL + '/api/v1/user/zoom/details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': siteSetting.webConfig.AUTHORISATION,
        "token": `${token}`,
      },
      body: JSON.stringify({
        meetingId,
        channelId
      })
    }).then((response) => {
      return response.json()
    }).then((data) => {

      setConfig({
        ...config,
        sdkKey: data.responseData.sdkKey,
        sdkSecret: data.responseData.sdkSecret,
        meetingNumber: data.responseData.meetingId,
        passWord: passWord,
        role: !zak ? 0 : 1,
        zakToken: data.responseData.zak,     // optional
        userName: data.responseData.userName,      //optional
        userEmail: data.responseData.email,    //optional
      });

    }).catch((error) => {
      console.log(error)
    })

  }

  useEffect(() => {
    callApi();
  }, [meetingId]);


  //connect socket 
  useEffect(() => {
    const token = urlParams.get('token');
    if (token) {
      socketInfo = socket(token);
    }
  }, [urlParams])


  return (
    <div className="App">
      <main>
        <h1>Meeting Will be start soon.</h1>
        {/* <button onClick={(e) => getSignature()}>Join Meeting</button> */}
      </main>
    </div>
  );
}

export default App;
