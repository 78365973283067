/**
 * @About
 * This file mange environment of the project
 */

//dev
export const devSetting = {
    webConfig: {
        API_URL: 'https://devapi.thesuccessfinder.com',
        SOCKET_URL: 'https://devsocket.thesuccessfinder.com',
        BASE_URL: 'https://stagtheater.thesuccessfinder.com',
        AUTHORISATION: 'Basic c3VjY2Vzc19maW5kZXI6c3VjY2Vzc19maW5kZXI='
    }
}

//stag
export const stagSetting = {
    webConfig: {
        API_URL: 'https://stagapi.thesuccessfinder.com',
        SOCKET_URL:'https://stagsocket.thesuccessfinder.com',
        BASE_URL: 'https://stagtheater.thesuccessfinder.com',
        AUTHORISATION: 'Basic c3VjY2Vzc19maW5kZXI6c3VjY2Vzc19maW5kZXI='
    }
}

//prod
export const prodSetting = {
    webConfig: {
        API_URL: 'https://api.thesuccessfinder.com',
        SOCKET_URL: "https://socket.thesuccessfinder.com",
        BASE_URL: 'https://theater.thesuccessfinder.com',
        AUTHORISATION: 'Basic c3VjY2Vzc19maW5kZXI6c3VjY2Vzc19maW5kZXI='
    }
}

//local
export const localhostSetting = {
    webConfig: {
       
        API_URL:"http://192.168.8.196:5007",
        SOCKET_URL:"http://192.168.8.196:4002",
        BASE_URL: 'http://localhost:3000',
        AUTHORISATION: 'Basic c3VjY2Vzc19maW5kZXI6c3VjY2Vzc19maW5kZXI='
    }
}


