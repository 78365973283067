import io from 'socket.io-client';
import siteSetting from './config/env';


let soket = (token) => {
    return io(`${siteSetting.webConfig.SOCKET_URL}`, {
        query: { token: token },
        transports: ['websocket'],
        auth: {
            token: token
        },
    });
}


export default soket;

