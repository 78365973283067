/**
 * @About 
 * This file is entry point of config
 */

import { devSetting, stagSetting, prodSetting, localhostSetting } from './Setting';


let siteSetting;
switch (process.env.REACT_APP_ENV) {

    case "local":
        siteSetting = localhostSetting;
        break;

    case "stag":
        siteSetting = stagSetting;
        break;

    case "prod":
        siteSetting = prodSetting;
        break;

    case "dev":
        siteSetting = devSetting;
        break;

    default:
        siteSetting = devSetting;
}
export default siteSetting;
